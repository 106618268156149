/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/19/2019
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as w51F1002Actions from "../../../redux/W51/W51F1002/W51F1002_actions";
import * as generalActions from "../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../config";
import { Row, Col, FormGroup } from "react-bootstrap";
import ButtonGeneral from "../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import { Combo, TextField } from "../../common/form-material";
import ActionToolbar from "../../common/toolbar/action-toolbar";
import { Column } from "devextreme-react/data-grid";
import GridContainer from "../../grid-container/grid-container";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Modal from "../../common/modal/modal";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import InlineSVG from "react-inlinesvg";

const styles = (theme) => ({
    panel: {
        transition: "all .4s",
    },
    panelForm: {
        transition: "all .5s",
        opacity: 1,
    },
    hiddenOpacity: {
        opacity: 0,
    },
    leftPanelMinimum: {
        width: "74px",
    },
    rightPanelMinimum: {
        width: "calc(100% - 74px)",
    },
    titleColorFilter: {
        backgroundColor: theme.palette.primary.main + " !important",
        "& svg": {
            "& path": {
                fill: theme.palette.primary.contrastText,
            },
        },
    },
});
class W51F1002 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loadingWareHouseID: false,

            isSaving: false,
            dataGrid: [],
            dataCboWareHouse: [],
            totalDataGrid: 0,
            minimum: false,

            selectedRowKeys: [],
            WareHouseID: [],
        };
        this.tmpSelectedRowKeys = [];
        this.tmpSelectedRowData = [];
        this.selectAll = false;
        this.changePage = false;
        this.filter = {
            limit: 20,
            skip: 0,
            strSearch: "",
        };
        this.keyNotAllowSelected = [];
        this.isSelectAll = -1;
    }

    loadCboWareHouse = () => {
        const { WareHouseIDDef } = this.props;
        const params = {
            FormID: "W51F1002",
            Language: Config.language || 84,
        };
        this.setLoading("WareHouseID", true);
        this.props.generalActions.getCboWarehouse(params, (errors) => {
            this.setLoading("WareHouseID", false);
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({ WareHouseID: [WareHouseIDDef] });
        });
    };

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ["loading" + key]: status });
    };

    componentDidMount() {
        this.loadCboWareHouse();
    }

    handleChange = (key, e) => {
        console.log(e)
        if (!key) return false;
        switch (key) {
            case "Search":
                this.filter.strSearch = e.target.value;
                break;
            case "WareHouseID":
                this.setState({ WareHouseID: e.value });
                break;
            default:
                break;
        }
    };

    loadGrid = (isReset) => {
        const { WareHouseID } = this.state;
        const {
            limit,
            skip,
            strSearch,
            // Project
        } = this.filter;
        const params = {
            FormID: "W51F1002",
            Language: Config.language || "84",
            DivisionID: Config.getDivisionID(),
            Equipment: strSearch ? strSearch : "",
            WareHouseID: WareHouseID && WareHouseID.length > 0 ? JSON.stringify(WareHouseID) : "",
            limit: limit,
            skip: skip,
        };

        if (!isReset) {
            this.changePage = true;
        }
        this.props.w51F1002Actions.getGridEquipments(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            let selectedRowKeys = [];

            if (data.rows && data.rows.length > 0) {
                data.rows.forEach((e) => {
                    if (this.tmpSelectedRowKeys.indexOf(e.EquipmentID) > -1) {
                        selectedRowKeys.push(e.EquipmentID);
                    }
                    this.keyNotAllowSelected = data.rows && data.rows.filter((d) => Number(d.Quantity) <= 0);
                    this.keyNotAllowSelected =
                        this.keyNotAllowSelected.length > 0 && this.keyNotAllowSelected.map((k) => k.EquipmentID && k.EquipmentID);
                });
            }
            this.setState({
                dataGrid: data.rows ? data.rows : data,
                totalDataGrid: data.total,
                selectedRowKeys: selectedRowKeys,
            });
        });
    };

    onFilter = () => {
        this.filter.skip = 0;
        this.loadGrid(true);
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    };

    onSelect = () => {
        const { onChosen } = this.props;
        let selectedRowData = this.tmpSelectedRowData;

        if (selectedRowData && selectedRowData.length > 0) {
            if (onChosen) onChosen(selectedRowData);
        } else {
            Config.popup.show("INFO", Config.lang("DHR_Ban_chua_chon_du_lieu_tren_luoi"));
            return false;
        }
    };

    setSelectedEquipments = (e) => {
        const currentSelectedRowKeys = e.currentSelectedRowKeys.length < 1 ? e.selectedRowKeys : e.currentSelectedRowKeys;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        const { dataGrid } = this.state;

        if (currentDeselectedRowKeys.length > 0) {
            this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
            this.tmpSelectedRowData = this.tmpSelectedRowData.filter((d) => {
                return currentDeselectedRowKeys.indexOf(d.EquipmentID) < 0;
            });
        }
        if (currentSelectedRowKeys.length > 0) {
            currentSelectedRowKeys.forEach((val) => {
                if (this.tmpSelectedRowKeys.indexOf(val) < 0) {
                    this.tmpSelectedRowKeys.push(val);
                }
            });
            dataGrid.forEach((val) => {
                const isExist = this.tmpSelectedRowData.filter((d) => d.EquipmentID === val.EquipmentID);
                if (this.tmpSelectedRowKeys.indexOf(val.EquipmentID) > -1 && isExist.length < 1) {
                    this.tmpSelectedRowData.push(val);
                }
            });
        }
    };

    onSelectionChanged = (e) => {
        const { selectedRowKeys } = e;
        const { dataGrid } = this.state;
        let keyNotAllowSelected = [];

        //Set is select alll....
        if (selectedRowKeys.length === dataGrid.length) {
            if (this.isSelectAll === -1 || this.isSelectAll === 0) {
                this.isSelectAll = 1;
            } else {
                this.isSelectAll = 0;
            }
        }

        e.currentDeselectedRowKeys.length > 1 &&
            e.currentSelectedRowKeys.forEach((key) => {
                if (this.keyNotAllowSelected.indexOf(key) > -1) {
                    keyNotAllowSelected.push(key);
                }
            });
        if (keyNotAllowSelected.length > 0) {
            e.component.deselectRows(keyNotAllowSelected);
            return;
        }

        if (this.isSelectAll === 0) {
            e.component.deselectAll();
            this.isSelectAll = -1;
            return;
        }
        if (this.changePage) {
            this.changePage = false;
        }
        this.setSelectedEquipments(e);
        this.setState({
            selectedRowKeys: e.selectedRowKeys,
        });
    };

    collapsePanel = (flag) => {
        if (typeof flag !== "undefined") {
            this.setState({ minimum: !!flag });
        } else {
            this.setState({ minimum: !this.state.minimum });
        }
    };

    showTitleHeaderBox = () => {
        let selectBox = document.getElementsByClassName("dx-header-row");
        if (selectBox.length > 0) {
            selectBox = selectBox[0].getElementsByClassName("dx-select-checkbox");
            if (selectBox.length > 0) {
                if (this.isSelectAll < 1) {
                    selectBox[0].setAttribute("title", Config.lang("DHR_Chon_tat_ca"));
                } else {
                    selectBox[0].setAttribute("title", Config.lang("DHR_Bo_tat_ca"));
                }
            }
        }
    };

    render() {
        const { classes, open, onClose, getCboWarehouse, isMode } = this.props;
        const { loading, isSaving, dataGrid, totalDataGrid, selectedRowKeys, minimum } = this.state;
        const { WareHouseID, loadingWareHouseID } = this.state;
        console.log(getCboWarehouse)
        return (
            <React.Fragment>
                <Modal open={open} maxWidth={"lg"} fullWidth={true}>
                    <Modal.Title disableTypography>
                        <ActionToolbar
                            alignment={"flex-end"}
                            title={Config.lang("DHR_Danh_sach_trang_thiet_bi_lao_dong")}
                            showBorder={false}
                            upperCase={false}
                            className={"text-uppercase"}
                            style={{
                                marginTop: 0,
                            }}
                        >
                            {isMode === 0 ? (
                                <ButtonGeneral
                                    name={Config.lang("DHR_Dong1")}
                                    typeButton={"cancel"}
                                    disabled={isSaving}
                                    className={"mgr5"}
                                    style={{ textTransform: "uppercase" }}
                                    size={"large"}
                                    onClick={onClose}
                                />
                            ) : (
                                <ButtonGeneral
                                    name={Config.lang("DHR_Dong1")}
                                    typeButton={"cancel"}
                                    disabled={isSaving}
                                    className={"mgr5"}
                                    style={{ textTransform: "uppercase" }}
                                    size={"large"}
                                    onClick={onClose}
                                />
                            )}
                            {isMode === 1 && (
                                <ButtonGeneral
                                    name={Config.lang("DHR_Chon")}
                                    color={"info"}
                                    variant={"contained"}
                                    loading={isSaving}
                                    style={{ textTransform: "uppercase" }}
                                    size={"large"}
                                    onClick={this.onSelect}
                                />
                            )}
                        </ActionToolbar>
                    </Modal.Title>
                    <Divider />
                    <Modal.Content>
                        <Row>
                            <Col
                                xs={12}
                                sm={4}
                                md={4}
                                lg={4}
                                className={classes.panel + " " + (minimum && window.innerWidth > 768 ? classes.leftPanelMinimum : "")}
                            >
                                <Row>
                                    <ActionToolbar
                                        alignment={"space-between"}
                                        // title={Config.lang("DHR_Loc_chon_nhan_vien")}
                                        showBorder={false}
                                        upperCase={false}
                                        className={minimum ? classes.titleColorFilter : ""}
                                        style={{
                                            marginTop: 0,
                                            backgroundColor: "#F7F9FC",
                                            padding: "0 15px",
                                            marginBottom: 15,
                                            fontSize: "1rem",
                                        }}
                                    >
                                        <div className={"display_row align-center valign-middle"}>
                                            <IconButton color="inherit" aria-label="menu" onClick={() => this.collapsePanel()}>
                                                <InlineSVG src={require("../../../assets/images/filter.svg")} />
                                            </IconButton>
                                            {!minimum && <Typography variant="h6" className={"align-center"}>
                                                {Config.lang("DHR_Loc_trang_thiet_bi_lao_dong")}
                                            </Typography>}
                                        </div>
                                        {!minimum && (
                                            <IconButton
                                                size={"small"}
                                                color="inherit"
                                                aria-label="menu"
                                                onClick={() => this.collapsePanel()}
                                            >
                                                <ChevronLeftIcon fontSize={"large"} />
                                            </IconButton>
                                        )}
                                    </ActionToolbar>
                                </Row>
                                <div className={classes.panelForm + " form-field " + (minimum ? "hide" : "")}>
                                    <FormGroup style={{ marginBottom: 5 }}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 text-uppercase"}>
                                                    {Config.lang("DHR_Trang_thiet_bi_lao_dong")}
                                                </label>
                                                <TextField
                                                    placeholder={Config.lang("DHR_Ten_Kho")}
                                                    variant={"outlined"}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={(e) => this.handleChange("Search", e)}
                                                    fullWidth
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{ marginBottom: 5 }}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 text-uppercase"}>{Config.lang("DHR_Kho")}</label>
                                                <Combo
                                                    dataSource={Config.storeDataSoureDevExtreme(getCboWarehouse)}
                                                    displayExpr={"WareHouseName"}
                                                    valueExpr={"WareHouseID"}
                                                    multiple={true}
                                                    margin={"dense"}
                                                    value={WareHouseID}
                                                    stylingMode={"outlined"}
                                                    loading={loadingWareHouseID}
                                                    placeholder={Config.lang("DHR_Ten_Kho")}
                                                    onValueChanged={(e) => this.handleChange("WareHouseID", e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    <FormGroup className={"display_row align_center valign-bottom"}>
                                        <ButtonGeneral
                                            name={Config.lang("DHR_Tim_kiem")}
                                            typeButton={"search"}
                                            color={"primary"}
                                            className={"pdl10 pdr10"}
                                            variant={"outlined"}
                                            style={{ textTransform: "uppercase" }}
                                            size={"large"}
                                            onClick={this.onFilter}
                                        />
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col
                                xs={12}
                                sm={8}
                                md={8}
                                lg={8}
                                className={
                                    classes.panel +
                                    " " +
                                    (window.innerWidth > 768 ? "bdl " + (minimum ? classes.rightPanelMinimum : "") : "")
                                }
                            >
                                <FormGroup>
                                    <GridContainer
                                        totalItems={totalDataGrid}
                                        itemPerPage={this.filter.limit}
                                        skipPerPage={this.filter.skip}
                                        listPerPage={[20, 30, 45, 60]}
                                        dataSource={dataGrid}
                                        disabled={isSaving}
                                        keyExpr={"EquipmentID"}
                                        gridProps={{
                                            style: {
                                                minHeight: 400,
                                            },
                                        }}
                                        pagerFullScreen={false}
                                        showBorders={false}
                                        columnAutoWidth={true}
                                        typeShort={window.innerWidth < 768}
                                        loadPanel={{
                                            enabled: loading,
                                        }}
                                        height={"calc(100vh - 180px)"}
                                        selection={{
                                            allowSelectAll: true,
                                            mode: "multiple",
                                            selectAllMode: "allPages",
                                            showCheckBoxesMode: "always",
                                        }}
                                        allowColumnResizing={true}
                                        selectedRowKey={selectedRowKeys}
                                        onChangePage={this.onChangePage}
                                        onChangePerPage={this.onChangePerPage}
                                        onSelectionChanged={this.onSelectionChanged}
                                        onEditorPreparing={(e) => {
                                            if (!e || !e.row) return;
                                            const { data } = e.row;
                                            if (data && data.Quantity && Number(data.Quantity) <= 0) {
                                                e.editorOptions.disabled = true;
                                            }
                                        }}
                                        onContentReady={() => {
                                            this.showTitleHeaderBox();
                                        }}
                                    >
                                        <Column
                                            caption={Config.lang("DHR_Ma_CCLD")}
                                            dataField={"EquipmentID"}
                                            alignment={"left"}
                                            width={150}
                                        />
                                        <Column caption={Config.lang("DHR_Ten_CCLD")} dataField={"EquipmentName"} />
                                        <Column caption={Config.lang("DHR_Don_vi_tinh")} dataField={"UnitID"} width={200} />
                                        <Column
                                            caption={Config.lang("DHR_Ton_kho")}
                                            dataField={"Quantity"}
                                            width={200}
                                            // cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                                        />
                                    </GridContainer>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }
}

W51F1002.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,

    onClose: PropTypes.func,
    onChosen: PropTypes.func,
};

export default compose(
    connect(
        (state) => ({
            getCboWarehouse: state.general.getCboWarehouse,
        }),
        (dispatch) => ({
            w51F1002Actions: bindActionCreators(w51F1002Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        }),
    ),
    withStyles(styles),
)(W51F1002);
