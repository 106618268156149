/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 10/05/2021
 * @Example
 */
import { IconButton } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Column } from "devextreme-react/data-grid";
import { Attachment, Button, Checkbox, CircularProgress, DatePicker, Dropdown, Modal, TextInput, Tooltip, Typography } from 'diginet-core-ui/components';
import Icon from 'diginet-core-ui/icons';
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../config";
import * as generalActions from "../../../redux/general/general_actions";
import * as W51F1101Actions from "../../../redux/W51/W51F1101/W51F1101_actions";
import { MForm } from "../../common/form-material";
import ActionToolbar from "../../common/toolbar/action-toolbar";
import UserImage from "../../common/user/user-image";
import GridActionBar from "../../grid-container/grid-actionbar";
import GridContainer from "../../grid-container/grid-container";
import History from "../../libs/history";
import W09F6000 from "../../W0X/W09/W09F6000/W09F6000";
import W51F1002 from "../W51F1002/W51F1002";

const styles = (theme) => ({
    btnActionBar: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    infoDistribution: {
        marginTop: 30,
        display: "flex",
        alignItems: 'center',
        justifyContent: "flex-start",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    avatarEmployees: {
        display: "flex",
        alignItems: "center",
        "& .user-picture-image": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 28,
            minWidth: 28,
        },
    },
    loadingContainer: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 9999,
    },
});

const W51F1101 = (props) => {
    const { classes, getCboWarehouse, location: { state: sateForm = {} }, flexibleColumn, wareHouseData = { NumValue: null } } = props;
    const FormID = "W51F1101";
    const Language = Config.language || "84";
    const { iPermission = null, mode = "", TransGroupID: transGroupID = "" } = sateForm;

    //STATE
    const [gridEquipLoading, setGridEquipLoading] = useState(false);
    const [gridEmployeeLoading, setGridEmployeeLoading] = useState(false);
    const [cboWareHouseLoading, setCboWareHouseLoading] = useState(false);
    const [showEmployeeSelect, setShowEmployeeSelect] = useState(false);
    const [showW51F1002Popup, setShowW51F1002Popup] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [btnSaveStatus, setBtnSaveStatus] = useState(false);
    const [TransGroupID, setTransGroupID] = useState(transGroupID);
    const [Mode, setMode] = useState(mode);
    const [error, setError] = useState({});
    const [dataMaster, setDataMaster] = useState({
        RetrieveDate: Mode === "add" ? new Date() : null,
    });
    const [employeeSelected, setEmployeeSelected] = useState({});
    const [dataDefaults, setDataDefaults] = useState([]);
    const [dataGridEmployee, setDataGridEmployee] = useState([]);
    const [dataGridEquipments, setDataGridEquipments] = useState([]);
    const [dataEquipments, setDataEquipments] = useState([]);
    const [employeeIDNotAllocated, setEmployeeIDNotAllocated] = useState([]);

    //REF
    const dataGrid01Ref = useRef(null);
    const dataGrid02Ref = useRef(null);
    const attRef = useRef(null);
    const dataMasterRef = useRef({});
    const dataMasterOld = useRef({});
    const getAttachment = useRef([]);
    const attachments = useRef([]);
    const dataGridEmployeeOld = useRef([]);
    const dataGridEquipmentsOld = useRef([]);

    const getInfo = () => {
        let status = false;
        if (_.isEmpty(sateForm)) {
            browserHistory.push(Config.getRootPath() + "W51F1100");
            return null;
        } else {
            status = true;
        }
        return status;
    }

    useEffect(() => {
        if (getInfo()) {
            if (_.isEmpty(flexibleColumn)) loadCaptions();
            if (Mode !== "view") loadCboWarehouse();
            if (Mode !== "add") {
                loadForm(0); // Load Master và  Lưới 1
                loadAttachments();
            }
            if (Mode === "add" && _.isEmpty(wareHouseData)) getWareHouse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_.isEmpty(dataEquipments)) {
            findDataEquipGridSelected(employeeSelected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataEquipments]);

    useEffect(() => {
        if (Mode === "add" && wareHouseData.NumValue === 1 && !_.isEmpty(getCboWarehouse)) {
            setDataMaster({
                ...dataMaster,
                WareHouseID: getCboWarehouse[0].WareHouseID,
                WareHouseName: getCboWarehouse[0].WareHouseName,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wareHouseData, getCboWarehouse]);

    const getWareHouse = () => {
        props.w51F1101Actions.getWareHouse((error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
        });
    }

    const findDataEquipGridSelected = (employeeSelect) => {
        if (_.isEmpty(employeeSelect)) return;
        const dataGridEquipmentsFilter = employeeSelect && dataEquipments && dataEquipments.filter(item => item.EmployeeID === employeeSelect.EmployeeID);
        const result = !_.isEmpty(dataGridEquipmentsFilter) ? dataGridEquipmentsFilter[0].GroupEmployee : [];
        setDataGridEquipments(result);
    }

    const loadAttachments = () => {
        const param = { KeyID: TransGroupID };
        props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            else if (data && data.length > 0) {
                getAttachment.current = data;
            }
        });
    };

    const loadCaptions = () => {
        const params = { FormID, Language, };
        props.w51F1101Actions.getCaption(params, (err) => {
            if (err) {
                Config.popup.show("INFO", err);
                return;
            }
        });
    };

    const loadCboWarehouse = () => {
        const params = {
            FormID,
            Language,
        };
        setCboWareHouseLoading(true);
        props.generalActions.getCboWarehouse(params, (err) => {
            setCboWareHouseLoading(false);
            if (err) {
                Config.popup.show("INFO", _.get(err, "err.message", Config.lang("Loi_chua_xac_dinh")));
                return;
            }
        });
    };

    const loadForm = (modeLoadForm = 0, IsChooseEmp = 0) => {
        const params = {
            Mode: modeLoadForm, //Mặc định Load Master và Lưới 1
            FormID,
            Language,
            TransGroupID,
            EmployeeID: "",
            IsChooseEmp, // Chọn từ Pop W09F6000
        };
        modeLoadForm === 0 ? setGridEmployeeLoading(true) : setGridEquipLoading(true)
        props.w51F1101Actions.loadForm(params, (error, data) => {
            modeLoadForm === 0 ? setGridEmployeeLoading(false) : setGridEquipLoading(false)
            if (error) {
                Config.popup.show("ERROR", error || Config.lang("Loi_chua_xac_dinh"));
                return false;
            }
            else if (data) {
                if (IsChooseEmp === 1) { // Load chọn từ Btn Chọn Nhân Viên
                    if (!_.isEmpty(data.Grid01)) {
                        const dataEmployees = _.uniqBy([...dataGridEmployee, ...data.Grid01], "EmployeeID");
                        const filterUnchecked = dataEmployees.filter(item1 => data.Grid01.some(item2 => item1.EmployeeID === item2.EmployeeID));
                        setDataDefaults({
                            selectedRowKeys: filterUnchecked.map(item => item.EmployeeID),
                            isLoaded: true
                        });
                        setDataGridEmployee(filterUnchecked);
                        if (_.isEmpty(employeeSelected)) setEmployeeSelected(data.Grid01[0]);
                    }
                    if (!_.isEmpty(data.Grid02)) {
                        let filterDataEquipments = [];
                        if (_.isEmpty(dataGridEquipments)) setDataGridEquipments(data.Grid02); // Ser default select đầu tiên 
                        const allEmployeeID = [...new Set(data.Grid02.map(item => item.EmployeeID))];
                        if (allEmployeeID) {
                            allEmployeeID.forEach(EmployeeID => {
                                filterDataEquipments.push({
                                    EmployeeID,
                                    GroupEmployee: data.Grid02.filter(item => item.EmployeeID === EmployeeID)
                                })
                            })
                        }
                        setDataEquipments(filterDataEquipments);
                    }
                } else { // Load Form 
                    if (modeLoadForm === 0) {
                        if (!_.isEmpty(data.Master)) { // Master
                            dataMasterOld.current = { ...data.Master };
                            dataMasterRef.current.Reason = data.Master.Reason;
                            setDataMaster(data.Master);
                        }
                        if (!_.isEmpty(data.Detail)) { // Mảng 1
                            dataGridEmployeeOld.current = data.Detail.map(item => ({ ...item }));
                            const selectedRowKeys = data.Detail.map(item => item.EmployeeID);
                            setDataDefaults({
                                selectedRowKeys,
                                isLoaded: true
                            });
                            setDataGridEmployee(data.Detail);
                            setEmployeeSelected(data.Detail[0]);
                            loadForm(1);
                        }
                    }
                    else if (modeLoadForm === 1 && !_.isEmpty(data)) { // Load Mảng 2 
                        const arrayData = parseDataEquipments(data);
                        dataGridEquipmentsOld.current = arrayData.map(item => ({ ...item }));
                        setDataEquipments(data);
                    }
                }
            }
        });
    };

    const parseDataEquipments = (data) => {
        if (_.isEmpty(data)) return;
        let dataGridEquipmentsData = [];
        data.map(item => item.GroupEmployee).forEach(item => {
            dataGridEquipmentsData.push(...item);
        });
        return dataGridEquipmentsData;
    }

    const onAddEmployee = () => {
        setShowEmployeeSelect(true);
    };

    const checkValidateMaster = (arrName) => {
        if (_.isEmpty(arrName)) return;
        const resultValidate = arrName.map(item => {
            return {
                name: item,
                rules: ["isRequired"],
                value: dataMaster[item]
            }
        });
        return resultValidate;
    }

    const setStateErrorText = (objValue) => {
        setError({ ...error, ...objValue });
        return Object.keys(objValue).length !== 0;
    }

    const checkEmployeeAllocation = (employeeId) => {
        return !_.some(dataEquipments, { EmployeeID: employeeId });
    };

    const onSaveApi = () => {
        let apiSave = "saveAdd";
        if (Mode === "edit") apiSave = "saveEdit";
        const { WareHouseID = "", RetrieveDate = null, } = dataMaster;
        const { Reason = "" } = dataMasterRef.current;
        const arrAttachment = JSON.stringify(getAttachment.current.concat(attachments.current));
        const dataGridEmployeeMapping = dataGridEmployee.map(item1 => ({
            ...item1,
            detail: dataEquipments.find(item2 => item1.EmployeeID === item2.EmployeeID)?.GroupEmployee || []
        }));
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID,
            Reason,
            TransGroupID,
            WareHouseID,
            RetrieveDate: moment.utc(RetrieveDate).format("YYYY-MM-DD"),
            arrAttachment,
            employee: JSON.stringify(dataGridEmployeeMapping),
        };
        setFormLoading(true);
        props.w51F1101Actions[apiSave](params, (error, data) => {
            setFormLoading(false);
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            else if (data) {
                if (data.Status === 0) {
                    if (data.TransGroupID) setTransGroupID(data.TransGroupID);
                    const status = onSaveHistory({ ...dataMaster, Reason }, data?.TransGroupID); // Thêm Reason từ Ref
                    if (status) {
                        setBtnSaveStatus(true);
                        if (Mode === "add") setMode("edit");
                        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                    } else {
                        Config.popup.show("ERROR", data.Message || Config.lang("Loi_chua_xac_dinh"));
                    }
                } else {
                    Config.popup.show("ERROR", data.Message || Config.lang("Loi_chua_xac_dinh"));
                }
            }
        });
    }

    const onSave = () => {
        const resultValidate = checkValidateMaster(["WareHouseID", "RetrieveDate"]);
        const validateForm = MForm.formValidation(resultValidate);
        const arrEmpIDNotAllocated = dataGridEmployee.map((item) => item.EmployeeID).filter(checkEmployeeAllocation);
        if (Mode !== "edit" && Object.keys(validateForm).length > 0) { // edit Read only
            setStateErrorText(validateForm);
            return false;
        }
        else if (!_.isEmpty(arrEmpIDNotAllocated)) {
            setEmployeeIDNotAllocated(arrEmpIDNotAllocated);
            Config.popup.show("INFO", Config.lang("Ton_tai_nhan_vien_chua_duoc_thu_hoi_trang_thiet_bi_ban_khong_duoc_phep_luu"));
        }
        else {
            if (!_.isEmpty(arrEmpIDNotAllocated)) setEmployeeIDNotAllocated([]);
            onSaveApi();
        }
    }

    const onSaveHistory = async (data, transGroupIDApi = "") => {
        if (_.isEmpty(data)) return;
        let action = 0; // add
        let status = false;
        let dataCompare = {};
        if (Mode === "edit") {
            dataCompare = dataMasterOld.current;
            action = 1;
        }
        const captions = {
            WareHouseName: "Kho",
            RetrieveDate: "Ngay_thu_hoi",
            Reason: "Ly_do",
            Detail: "Chi_tiet_nhan_vien",
        };
        const TransID = transGroupIDApi ? transGroupIDApi : TransGroupID;
        const options = {
            data,
            dataCompare,
            captions,
            action,
            ModuleID: "D51",
            TransactionID: "W51F1100",
            TransID,
            TransactionName: Config.lang("Thu_hoi_trang_thiet_bi_lao_dong"),
            itemRender: (e, type) => {
                const { key, data, oldData } = e;
                if (key === "RetrieveDate") {
                    let reTrieveDateValue = data[key];
                    if (action === 1 && type === "Content_OLD") reTrieveDateValue = oldData[key];
                    return moment.utc(reTrieveDateValue).format("DD//MM/YYYY");
                }
            }
        };
        const history = new History(options);
        const captionEmployeeGrid = {
            EmployeeID: "Ma_nhan_vien",
            EmployeeName: "Ten_nhan_vien",
        };
        const captionEquipmentGrid = {
            EmployeeID: "Ma_nhan_vien",
            EmployeeName: "Ten_nhan_vien",
            IsCheck: "Thu_hoi",
            EquipmentName: "Cong_cu_lao_dong",
            NoteU: "Ly_do_thu_hoi",
            StatusName: "Tinh_trang_thu_hoi",
        };
        const dataNewEquipments = parseDataEquipments(dataEquipments);
        const dataNewEquipmentFilter = dataNewEquipments.filter(item => item.IsCheck === 1);
        history.createDetailHistory("Chi_tiet_nhan_vien", dataGridEmployee, dataGridEmployeeOld.current, captionEmployeeGrid, "EmployeeID", null, options);
        history.createDetailHistory("Chi_tiet_cong_cu_lao_dong", dataNewEquipmentFilter, dataGridEquipmentsOld.current, captionEquipmentGrid, "EquipmentID", null, options);
        if (history.get()?.length > 0) {
            if (await history.save()) {
                status = 200;
            }
        } else {
            status = 200;
        }
        return status;
    }

    const onDeleteRow = (e) => {
        let delCondition = false;
        if (!_.isEmpty(dataGridEquipments)) {
            delCondition = dataGridEquipments.some(item => item.IsCheck === 1);
        }
        if (delCondition) {
            Config.popup.show("INFO", Config.lang("Nhan_vien_da_duoc_thu_hoi_ccld_khong_duoc_phep_xoa"));
            return;
        } else {
            Config.popup.show("YES_NO", `${Config.lang("Ban_co_chac_muon_xoa_nhan_vien?")}`, () => {
                const { EmployeeID = "" } = e.data;
                e.component.deleteRow(e.rowIndex);
                if (EmployeeID === employeeSelected.EmployeeID) {
                    const filterDataEquipment = dataEquipments.filter(item => item.EmployeeID !== EmployeeID);
                    setEmployeeSelected(dataGridEmployee[0] || {}); // Mặc định select đầu tiên 
                    setDataEquipments(filterDataEquipment);
                    setDataGridEquipments([]); // Xóa data lưới Equip đã xóa hiện tại
                    const selectedRowKeys = dataGridEmployee.map(item => item.EmployeeID);
                    setDataDefaults({
                        selectedRowKeys,
                        isLoaded: true
                    })
                }
            });
        }
    };

    const renderAction = (e) => {
        return (
            <GridActionBar>
                <IconButton
                    disabled={false}
                    aria-label={"delete"}
                    style={{ padding: 0, width: 30, height: 30, marginRight: 3, cursor: 'pointer' }}
                >
                    <Icon name={"Delete"} onClick={() => onDeleteRow(e)} />
                </IconButton>
            </GridActionBar>
        );
    };

    const handleChangeState = (fieldName = "", e) => {
        let value = "";
        let dataMasterObj = {
            ...dataMaster,
            [fieldName]: value
        };
        switch (fieldName) {
            //DropDown
            case "WareHouseID":
                const positionID = fieldName.indexOf("ID");
                const standardDDName = fieldName.slice(0, positionID);
                const DDName = standardDDName + "Name";
                dataMasterObj[fieldName] = e.value;
                dataMasterObj[DDName] = e.data ? e.data[DDName] : "" // DD Name
                break;
            //DatePicker
            case "RetrieveDate":
                dataMasterObj[fieldName] = e.value;
                break;
            default:
                break;
        }
        if (fieldName) setDataMaster(dataMasterObj);
        if (error && error.hasOwnProperty(fieldName)) { // Remove error
            const removedError = _.omit(error, fieldName);
            setError(removedError);
        }
    }

    const handleChangeRef = (fieldName = "", e) => {
        let value = "";
        let dataMasterObj = {
            ...dataMasterRef,
            [fieldName]: value
        };
        switch (fieldName) {
            case "Reason":
                dataMasterObj[fieldName] = e.target.value;
                break;

            default:
                break;
        }
        dataMasterRef.current = dataMasterObj;
    }

    const renderCaption = (grid) => {
        const captionGrid = _.get(flexibleColumn, grid, false);
        if (!captionGrid) return;
        const width = grid === "grid1" ? 170 : 280;
        return captionGrid
            .filter((item) => !item.Disabled)
            .map((item, ind) => {
                let format = {};
                if (item.IsMoney === 1) format = { format: { type: "fixedPoint" } };
                return (
                    <Column
                        width={width}
                        allowEditing={true}
                        key={ind}
                        caption={item.RefCaption}
                        dataField={item.RefID}
                        alignment={"center"}
                        {...format}
                    />
                );
            });
    };

    const onBack = () => {
        browserHistory.goBack();
    }

    const onChosenAllocation = (data) => {
        const arrEquipAllocated = dataGridEmployee
            .filter((item) => item.EmployeeID === employeeSelected.EmployeeID)
            .reduce((arr, item) => {
                arr.push(
                    ...data.map(({ EquipmentName, EquipmentID, UnitName, UnitID }) => ({
                        EmployeeID: item.EmployeeID,
                        EquipmentName,
                        UsingPeriodFrom: moment().format("YYYY-MM-DD"),
                        UsingPeriodTo: "",
                        Quantity: 1,
                        EquipmentID,
                        UnitName: UnitName || "",
                        UnitID,
                    })),
                );
                return arr;
            }, []);
        if (!_.isEqual(arrEquipAllocated, dataGridEquipments)) {
            const dataUnique = _.uniqBy([...dataGridEquipments, ...arrEquipAllocated], (v) => [v.EmployeeID, v.EquipmentID].join())
            setDataGridEquipments(dataUnique);
            setShowW51F1002Popup(false);
            Config.notify.show("success", Config.lang("CCLD_da_cap_phat"), 2000);
        }
    };

    const onCloseModal = (modalName = "") => {
        switch (modalName) {
            case "selectEmployee":
                setShowEmployeeSelect(false);
                break;
            default:
                break;
        }
    }

    const onSelectedEmployee = () => {
        loadForm(0, 1);
        onCloseModal("selectEmployee"); // load xong đóng modal
    }

    const onSelectionChangedGridEmployee = (e) => {
        const selectedRow = e.selectedRowsData[0];
        if (e.component.hasEditData()) e.component.saveEditData();
        setEmployeeSelected(selectedRow);
        findDataEquipGridSelected(selectedRow); // Set lưới 2 theo Employ
    }

    const onChangeAttachments = (e) => {
        attachments.current = e.allNewAttached;
        if (!_.isEmpty(getAttachment.current) && getAttachment.current.length > e.oldAttached.length) {
            getAttachment.current = e.oldAttached;
        }
    };

    const cellRenderRadio = (e) => {
        return (
            <Checkbox
                label={""}
                color={"primary"}
                disabled={Mode === "edit" || Mode === "view"}
                style={{ margin: 'auto' }}
                value={e.data.IsCheck === 1}
                checked={e.data.IsCheck === 1}
                onChange={(chk) => {
                    e.component.cellValue(e.rowIndex, "IsCheck", chk.target.checked ? 1 : 0);
                    e.component.saveEditData();
                }}
            />
        )
    }

    const renderEmpProfile = (e) => {
        const { data } = e.row;
        const employeeId = _.get(data, "EmployeeID", "");
        let renderIconWarning = "";
        if (_.includes(employeeIDNotAllocated, _.get(data, "EmployeeID", ""))) {
            renderIconWarning = (
                <Tooltip title={Config.lang("Nhan_vien_chua_duoc_cap_phat_CCLD")} arrow={"true"}>
                    <Icon name={"Warning"} style={{ color: "#FFCC00", marginRight: 4 }} />
                </Tooltip>
            );
        }
        return (
            <div style={{ display: "flex" }}>
                {renderIconWarning}
                <div className={classes.avatarEmployees}>
                    <UserImage width={28} height={30} data={data} allowHover={false} />
                    {employeeId}
                </div>
            </div>
        );
    }

    if (!iPermission) return null;
    return (
        <>
            {formLoading &&
                <div className={classes.loadingContainer}>
                    <CircularProgress size={"sm"} />
                </div>}
            {showEmployeeSelect &&
                <W09F6000
                    dataDefaults={dataDefaults}
                    open={showEmployeeSelect}
                    FormID={"W51F1101"}
                    onChosen={onSelectedEmployee}
                    onClose={() => onCloseModal("selectEmployee")}
                />
            }
            <Modal
                zIndex={1020}
                width={"1280"}
                open={showW51F1002Popup}>
                <W51F1002
                    isMode={0}
                    FormID={"W51F1002"}
                    open={showW51F1002Popup}
                    onClose={() => setShowW51F1002Popup(false)}
                    onChosen={onChosenAllocation}
                    WareHouseIDDef={dataMaster.warehouseID || []}
                />
            </Modal>
            <FormGroup className={"gird-w51f1100"}>
                <ActionToolbar title={Config.lang("Thu_hoi_trang_thiet_bi_lao_dong")} onBack={onBack}>
                    <div className={classes.btnActionBar}>
                        <Button
                            disabled={Mode === "view"}
                            size={"medium"}
                            color={"primary"}
                            viewType={"filled"}
                            startIcon={"AddPerson"}
                            label={Config.lang("Chon_nhan_vien")}
                            onClick={onAddEmployee}
                        />
                        <Button
                            disabled={btnSaveStatus || Mode === "view"}
                            size={"medium"}
                            color={"info"}
                            viewType={"filled"}
                            startIcon={"SaveV2"}
                            label={Config.lang("Luu")}
                            onClick={onSave}
                        />
                    </div>
                </ActionToolbar>
                <FormGroup style={{ marginTop: 10 }}>
                    <Row>
                        <Col lg={3} md={3} sm={6} xs={12}>
                                <Dropdown
                                    required={true}
                                    clearAble={true}
                                    placeholder={Config.lang("Chon")}
                                    label={
                                        <>
                                            <Icon name={"Home"}
                                                style={{ position: 'absolute', top: '-5px', left: '6px' }}
                                                onClick={() => setShowW51F1002Popup(true)} />
                                            <span style={{
                                                position: 'relative',
                                                marginLeft: 40
                                            }}>{Config.lang("Kho")} </span>
                                        </>
                                    }
                                    valueExpr={"WareHouseID"}
                                    displayExpr={"WareHouseName"}
                                    error={error && error["WareHouseID"]}
                                    onChange={e => handleChangeState("WareHouseID", e)}
                                    dataSource={getCboWarehouse}
                                    value={dataMaster.WareHouseID}
                                    loading={cboWareHouseLoading}
                                    disabled={Mode === "view" || Mode === "edit" || (Mode === "add" && dataMaster.NumValue === 0)}
                                    defaultValue={Mode === "add" && !_.isEmpty(getCboWarehouse) ? getCboWarehouse[0].WareHouseName : ""}
                                />
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={12}>
                            <DatePicker
                                width={"100%"}
                                margin={"normal"}
                                required={Mode === "add"}
                                disabled={Mode === "edit" || Mode === "view"}
                                placeholder={"dd/mm/yyyy"}
                                label={Config.lang("Ngay_thu_hoi")}
                                error={error && error["RetrieveDate"]}
                                value={dataMaster.RetrieveDate}
                                onChange={e => handleChangeState("RetrieveDate", e)}
                            />
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                                disabled={Mode === "view"}
                                label={Config.lang("Ly_do")}
                                placeholder={Config.lang("Nhap")}
                                onChange={e => handleChangeRef("Reason", e)}
                                value={dataMasterRef.current.Reason}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <GridContainer
                    reference={(ref) => (dataGrid01Ref.current = ref)}
                    columnAutoWidth={true}
                    hoverStateEnabled={true}
                    showColumnLines={false}
                    showRowLines={false}
                    showBorders={false}
                    editing={{
                        texts: { confirmDeleteMessage: "" },
                        mode: "cell",
                        allowUpdating: Mode !== "view",
                        startEditAction: "click",
                    }}
                    selection={{ mode: "single" }}
                    onSelectionChanged={onSelectionChangedGridEmployee}
                    loading={gridEmployeeLoading}
                    style={{ border: "none" }}
                    noDataText={Config.lang("No_data")}
                    dataSource={dataGridEmployee}
                    onContentReady={(e) => {
                        let grid = e.component;
                        let selection = grid.getSelectedRowKeys();
                        if (selection.length === 0) {
                            grid.selectRowsByIndexes([0]);
                        }
                    }}
                >
                    <Column
                        alignment={"center"}
                        allowEditing={false}
                        visible={Config.isMobile && Mode !== "view"}
                        caption={Config.lang("Hanh_dong")}
                        cellRender={renderAction}
                    />
                    <Column
                        width={170}
                        allowEditing={false}
                        dataField={"EmployeeID"}
                        cellRender={renderEmpProfile}
                        caption={Config.lang("Ma_nhan_vien")}
                    />
                    <Column
                        width={280}
                        allowEditing={false}
                        dataField={"EmployeeName"}
                        caption={Config.lang("Ten_nhan_vien")}
                    />
                    <Column
                        width={280}
                        allowEditing={false}
                        dataField={"OrgChartName"}
                        caption={Config.lang("Co_cau_to_chuc")}
                    />
                    <Column
                        width={280}
                        allowEditing={false}
                        dataField={"ProjectName"}
                        caption={Config.lang("Du_an")}
                    />
                    <Column
                        minWidth={90}
                        allowEditing={false}
                        caption={Config.lang("Ngay_vao_lam")}
                        dataField={"DateJoined"}
                        alignment={"center"}
                        dataType={"date"}
                        format={"dd/MM/yyyy"}
                    />
                    <Column
                        minWidth={90}
                        allowEditing={false}
                        caption={Config.lang("Ngay_nghi_viec")}
                        dataField={"DateLeft"}
                        alignment={"center"}
                        dataType={"date"}
                        format={"dd/MM/yyyy"}
                    />
                    {renderCaption("grid1")}
                    <Column
                        fixed={true}
                        allowEditing={false}
                        alignment={"center"}
                        fixedPosition={"right"}
                        visible={!Config.isMobile && Mode !== "view"}
                        cellRender={renderAction}
                    />
                </GridContainer>

                <FormGroup>
                    <div className={classes.infoDistribution}>
                        <Typography style={{ marginBottom: 6, fontWeight: 400 }} variant={"subtitle1"}>
                            {Config.lang("Thong_tin_thu_hoi_cho")}
                        </Typography>
                        <div style={{ width: Config.isMobile ? "100%" : "23%", margin: `0px ${Config.isMobile ? 0 : 25}px` }}>
                            <TextInput
                                variant={"standard"}
                                value={!_.isEmpty(employeeSelected)
                                    ? `${employeeSelected.EmployeeID} ${employeeSelected.EmployeeName ? "- " + employeeSelected.EmployeeName : ""
                                    }` : ""}
                                startIcon={<div className={classes.avatarEmpSelect}>
                                    <UserImage height={24} width={24} data={employeeSelected || []} />
                                </div>}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                            />
                        </div>
                    </div>
                </FormGroup>
                <GridContainer
                    reference={(ref) => (dataGrid02Ref.current = ref)}
                    hoverStateEnabled={true}
                    showColumnLines={false}
                    columnAutoWidth={true}
                    showRowLines={false}
                    showBorders={false}
                    typeShort={true}
                    editing={{
                        texts: { confirmDeleteMessage: "" },
                        Mode: "cell",
                        allowUpdating: Mode !== "view",
                        startEditAction: "click",
                    }}
                    onEditingStart={(e) => {
                        if (!e) return;
                        if (e.data.IsCheck === 0) e.cancel = true;
                    }}
                    keyExpr={"EquipmentID"}
                    style={{ border: "none" }}
                    selection={{ Mode: "none" }}
                    loading={gridEquipLoading}
                    dataSource={dataGridEquipments}
                    noDataText={Config.lang("No_data")}
                >
                    <Column
                        allowEditing={false}
                        alignment={"center"}
                        dataField={"IsCheck"}
                        caption={Config.lang("Thu_hoi")}
                        cellRender={cellRenderRadio}
                    />
                    <Column
                        width={90}
                        allowEditing={false}
                        alignment={"center"}
                        caption={Config.lang("STT")}
                        cellRender={(e) => e.rowIndex + 1}
                    />
                    <Column
                        minWidth={280}
                        allowEditing={false}
                        dataField={"EquipmentName"}
                        cellRender={(e) => {
                            const { EquipmentID = "", EquipmentName = "", } = e.data;
                            return `${EquipmentID} - ${EquipmentName}`
                        }}
                        caption={Config.lang("Cong_cu_lao_dong")}
                    />
                    <Column
                        width={90}
                        allowEditing={false}
                        dataField={"UnitID"}
                        caption={Config.lang("DVT")}
                    />
                    <Column
                        width={90}
                        allowEditing={false}
                        dataField={"Quantity"}
                        caption={Config.lang("So_luong")}
                    />
                    <Column
                        minWidth={90}
                        allowEditing={false}
                        dataField={"UsingPeriod"}
                        caption={Config.lang("So_thang_su_dung")}
                    />
                    <Column
                        minWidth={90}
                        allowEditing={false}
                        caption={Config.lang("Ngay_cap_phat")}
                        dataField={"UsingPeriodFrom"}
                        alignment={"center"}
                        dataType={"date"}
                        format={"dd/MM/yyyy"}
                    />
                    <Column
                        minWidth={90}
                        allowEditing={false}
                        caption={Config.lang("Ngay_het_han")}
                        dataField={"UsingPeriodTo"}
                        alignment={"center"}
                        dataType={"date"}
                        format={"dd/MM/yyyy"}
                    />
                    {renderCaption("grid2")}
                    <Column
                        width={350}
                        allowEditing={Mode === "edit" || Mode === "add"}
                        dataField={"NoteU"}
                        caption={Config.lang("Ly_do_thu_hoi")}
                    />
                    <Column
                        width={280}
                        allowEditing={Mode === "edit" || Mode === "add"}
                        dataField={"StatusName"}
                        caption={Config.lang("Tinh_trang_thu_hoi")}
                    />
                </GridContainer>
                <Attachment
                    ref={ref => attRef.current = ref}
                    style={{ marginTop: 64 }}
                    multiple={true}
                    maxFile={5}
                    data={getAttachment.current}
                    onChange={onChangeAttachments}
                />
            </FormGroup>
        </>
    );
};

export default compose(
    connect((state) => ({
        getCboWarehouse: state.general.getCboWarehouse,
        flexibleColumn: state.W51F1101.flexibleColumn,
        wareHouseData: state.W51F1101.wareHouseData,
    }), (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w51F1101Actions: bindActionCreators(W51F1101Actions, dispatch),
    })), withStyles(styles))(W51F1101);
