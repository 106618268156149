/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 6/3/2020
 * @Example
 */

import React, { Component } from "react";
import { withStyles, TextField, IconButton, Typography, InputAdornment, Tooltip } from "@material-ui/core";
import { browserHistory } from "react-router";
import Modal from "../../common/modal/modal";
import * as W51F1001Actions from "../../../redux/W5X/W51F1001/W51F1001_actions";
import * as generalActions from "../../../redux/general/general_actions";
import ButtonCustom from "../../common/button/button-custom";
import ButtonGeneral from "../../common/button/button-general";
import ActionToolbar from "../../common/toolbar/action-toolbar";
import W09F6000 from "../../W0X/W09/W09F6000/W09F6000";
import InlineSVG from "react-inlinesvg";
import Config from "../../../config";
import { FormGroup, Row, Col } from "react-bootstrap";
import DateBoxPicker from "../../common/form-material/date-box";
import GridContainer from "../../grid-container/grid-container";
import { Column, RequiredRule, CustomRule, KeyboardNavigation } from "devextreme-react/data-grid";
import Attachments from "../../common/attachments/attachments";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import HouseIcon from "@material-ui/icons/House";
import WarningIcon from "@material-ui/icons/Warning";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import _ from "lodash";
import { Combo, MForm } from "../../common/form-material";
import moment from "moment";
import Api from "../../../services/api";
import UserImage from "../../common/user/user-image";
import W51F1002 from "../W51F1002/W51F1002";
import CDN from "../../CDN";
import Icons from "../../common/icons/";
import GridActionBar from "../../grid-container/grid-actionbar";

const styles = (theme) => ({
    buttonHeader: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    dataGrid: {
        "& .dx-datagrid-rowsview": {
            "& tr.dx-row.dx-data-row": {
                "& .grid-action-hover": {
                    marginTop: "0.3%",
                },
                "& .dx-datagrid.dx-row > td": {
                    paddingTop: "0 !important",
                    paddingBottom: "0 !important",
                },
            },
            "& .dx-datagrid-validator.dx-validator.dx-visibility-change-handler.dx-editor-cell.dx-datagrid-invalid": {
                "& .dx-texteditor-container": {
                    "& .dx-texteditor-input-container": {
                        border: "none",
                        backgroundColor: "#EFBAB6",
                    },
                },
            },
        },
    },
    buttonCustom: {
        [theme.breakpoints.up("md")]: {
            paddingTop: 24,
        },
        [theme.breakpoints.down("md")]: {
            paddingTop: 0,
        },
        [theme.breakpoints.down("sm")]: {
            paddingTop: 0,
        },
    },
    infoDistribution: {
        marginTop: 30,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    labelWithIcon: {
        "& .MuiTypography-body1": {
            color: "rgba(0, 0, 0, 0.54)",
        },
    },
    attachmentIcon: {
        "&.MuiSvgIcon-root": {
            transform: "rotate(45deg)",
        },
    },
    avatarEmployees: {
        display: "flex",
        alignItems: "center",
        "& .user-picture-image": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 28,
            minWidth: 28,
        },
    },
    avatarEmpSelect: {
        "& .user-picture-image": {
            minHeight: 24,
            minWidth: 24,
            marginBottom: 4,
        },
    },
    tooltipWarehouse: {
        top: "-1.5% !important",
    },
});

class W51F1001 extends Component {
    constructor(props) {
        super(props);
        this.FormID = "W51F1001";
        this.Language = Config.language || "84";
        this.state = {
            employeeSelected: {},
            employeeIDNotAllocated: [],
            masterData: {
                warehouseID: "",
                reason: "",
                examineDate: "",
            },
            isSaved: false,
            delRow: false,
            uploading: false,
            attachmentLoading: false,
            gridEmployees: [],
            gridEquipments: [],
            dataOldAttachments: [],
            gridEmpLoading: false,
            gridEquipLoading: false,
            showEmployeeSelect: false,
            disabled: false,
            disableSave: false,
            loadingSave: false,
            isMode: null,
            edit: true,
            rowData: null,
            showW51F1002Popup: false,
        };
        this.attachments = [];
        this.deletedFile = [];
        this.setTimeoutId = "";
        this.newRowIndex = false;
    }

    loadCaptions = () => {
        const { FormID, Language } = this;
        const params = {
            FormID,
            Language,
        };
        this.setState({ gridEmpLoading: true, gridEquipLoading: true });
        this.props.w51f1001Actions.getCaption(params, (err, res) => {
            this.setState({ gridEmpLoading: false, gridEquipLoading: false });
            const captionGrid1 = _.get(res, "grid1", false);
            const captionGrid2 = _.get(res, "grid2", false);
            if (err) {
                Config.popup.show("INFO", err);
                return;
            }
            if (!captionGrid1) return;
            this.setState({
                gridEmployees: this.state.gridEmployees.map((item) => ({
                    ...item,
                    ...captionGrid1.reduce((obj, item) => {
                        obj[item.RefID] = 0;
                        return obj;
                    }, {}),
                })),
                gridEquipments: this.state.gridEquipments.map((item) => ({
                    ...item,
                    ...captionGrid2.reduce((obj, item) => {
                        obj[item.RefID] = 0;
                        return obj;
                    }, {}),
                })),
            });
        });
    };

    loadCboWarehouse = () => {
        const { masterData } = this.state;
        const { FormID, Language } = this;
        const params = {
            FormID,
            Language,
        };
        this.setState({ disabled: true });
        this.props.generalActions.getCboWarehouse(params, (err, data) => {
            this.setState({ disabled: false });
            const warehouseID = _.get(data, "[0]WareHouseID", "");
            if (err) {
                Config.popup.show("INFO", _.get(err, "err.message", Config.lang("DHR_Loi_chua_xac_dinh")));
                return;
            }
            this.setState({ masterData: { ...masterData, warehouseID } });
        });
    };

    loadMaster = () => {
        const { FormID, Language } = this;
        const { TransGroupID } = this.getInfo();
        const params = {
            FormID,
            Language,
            TransGroupID,
        };
        this.setState({ gridEmpLoading: true, disabled: true });
        this.props.w51f1001Actions.getMaster(params, (err, res) => {
            this.setState({ gridEmpLoading: false, disabled: false });
            const rows = _.get(res, "rows", false);
            if (err) {
                Config.popup.show("INFO", err);
                return;
            }
            if (!rows) return;
            const gridEmployees = rows.map((item) => ({
                ...item,
                EmployeeName: _.get(Config.getUser({ EmployeeID: item.EmployeeID }), "EmployeeName", ""),
            }));
            this.setState({
                masterData: {
                    warehouseID: _.get(res, "WareHouseID", ""),
                    reason: _.get(res, "Reason", ""),
                    examineDate: _.get(res, "ExamineDate", ""),
                },
                gridEmployees,
                employeeSelected: { ...gridEmployees[0] },
            });
        });
    };

    reloadDetail = async () => {
        const { FormID, Language } = this;
        const { gridEmployees, gridEquipments } = this.state;
        const arrEmpIDNotAllocated = gridEmployees.map((item) => item.EmployeeID).filter(this.checkEmployeeAllocation);
        if (_.isEmpty(arrEmpIDNotAllocated)) {
            Config.popup.show("INFO", Config.lang("DHR_Tat_ca_nhan_vien_da_duoc_cap_phat_CCLD"));
            return;
        }
        const params = {
            FormID,
            Language,
            data: JSON.stringify(gridEmployees.map((item) => item.EmployeeID)),
        };
        this.setState({ gridEmpLoading: true, gridEquipLoading: true, disabled: true });
        const res = await Api.put("/w51f1001/coppy", params);
        this.setState({ gridEmpLoading: false, gridEquipLoading: false, disabled: false });
        if (_.get(res, "code", false) === 200) {
            this.setState(
                {
                    gridEquipments: _.uniqBy([...gridEquipments, ...res.data], (v) => [v.EmployeeID, v.EquipmentID].join()),
                },
                () => {
                    Config.notify.show("success", Config.lang("DHR_Da_sao_chep_cap_phat_truoc"), 2000);
                },
            );
        } else {
            Config.popup.show("INFO", _.get(res, "message", Config.lang("DHR_Loi_chua_xac_dinh")));
        }
    };

    loadDetail = () => {
        const { FormID, Language } = this;
        const { TransGroupID } = this.getInfo();
        const params = {
            FormID,
            Language,
            TransGroupID,
        };
        this.setState({ gridEquipLoading: true });
        this.props.w51f1001Actions.getDetail(params, (err, data) => {
            this.setState({ gridEquipLoading: false });
            if (err) {
                Config.popup.show("INFO", _.get(err, "message", Config.lang("DHR_Loi_chua_xac_dinh")));
                return;
            }
            if (!data) return;
            this.setState({
                gridEquipments: data,
            });
        });
    };

    loadSelectedEmployees = async () => {
        const { Language, FormID } = this;
        const { gridEmployees } = this.state;
        const { mode } = this.props;
        const params = {
            FormID,
            Language,
        };
        this.setState({ gridEmpLoading: true });
        const res = await Api.put("/w51f1001/load-choose", params);
        this.setState({ gridEmpLoading: false });
        if (_.get(res, "code", false) === 200) {
            const employeeSelected = _.get(res, "data", []).map((item) => ({
                ...item,
                EmployeeName: _.get(Config.getUser({ EmployeeID: item.EmployeeID }), "EmployeeName", ""),
            }));
            if (!employeeSelected) return;
            const dataEmployees = _.uniqBy([...gridEmployees, ...employeeSelected], "EmployeeID");
            !_.isEqual(dataEmployees, gridEmployees) &&
                this.setState({ gridEmployees: dataEmployees, employeeSelected: { ...dataEmployees[0] } }, () => {
                    if (mode === "edit") this.loadDetail();
                });
        } else {
            const message = _.get(res, "message", Config.lang("DHR_Loi_chua_xac_dinh"));
            Config.popup.show("INFO", Config.lang(message));
        }
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : "",
            });
        });
        if (!_.isEmpty(dataOldAttachments)) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    getInfo = (flag) => {
        const { location } = this.props;
        if (location && location.state) {
            return {
                mode: _.get(location, "state.mode", ""),
                TransGroupID: _.get(location, "state.TransGroupID", ""),
                isPermissionForm: _.get(location, "state.isPermissionForm", 0),
            };
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W51F1000");
                return null;
            }
            return false;
        }
    };

    loadAttachments = () => {
        const { TransGroupID } = this.getInfo();
        const param = {
            KeyID: TransGroupID,
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data,
                    attachmentLoading: false,
                });
            }
            this.setState({
                loading: false,
            });
        });
    };

    checkEquipmentInventory = async () => {
        const { gridEquipments, masterData } = this.state;
        const { FormID, Language } = this;
        const { mode, TransGroupID } = this.getInfo();
        const params = {
            Mode: mode === "edit" ? 1 : 0,
            FormID,
            Language,
            TransGroupID,
            DivisionID: Config.getDivisionID(),
            WareHouseID: masterData.warehouseID,
        };
        this.setState({ gridEmpLoading: true, gridEquipLoading: true });
        const res = await Api.put("/w51f1001/count", params);
        this.setState({ gridEmpLoading: false, gridEquipLoading: false });
        if (_.get(res, "code", false) === 200) {
            let objOldQuantity = {};
            const objEquipmentQuantity = gridEquipments.reduce((obj, item, arr) => {
                obj[item.EquipmentID] = item.Quantity + (objOldQuantity[item.EquipmentID] || 0);
                objOldQuantity[item.EquipmentID] = item.Quantity;
                return obj;
            }, {});
            return res.data
                .filter((item) => _.includes(Object.keys(objEquipmentQuantity), item.EquipmentID))
                .filter((item) => item.Quantity - objEquipmentQuantity[item.EquipmentID] < 0)
                .map((itemRes) => ({
                    name: gridEquipments.filter((itemEquip) => itemEquip.EquipmentID === itemRes.EquipmentID)[0].EquipmentName,
                    inventory: itemRes.Quantity,
                    allocated: objEquipmentQuantity[itemRes.EquipmentID],
                }));
        } else {
            const message = _.get(res, "message", Config.lang("DHR_Loi_chua_xac_dinh"));
            Config.popup.show("INFO", Config.lang(message));
        }
    };

    checkEmployeeAllocation = (employeeId) => {
        const { gridEquipments } = this.state;
        return !_.some(gridEquipments, { EmployeeID: employeeId });
    };

    onViewAllocations = () => {
        this.setState({
            isMode: 0,
            edit: false,
            rowData: null,
            showW51F1002Popup: true,
        });
    };

    closePopupAllocation = () => {
        this.setState({
            showW51F1002Popup: false,
        });
    };

    onChosenAllocation = (data) => {
        const { gridEmployees, gridEquipments, employeeSelected } = this.state;
        const arrEquipAllocated = gridEmployees
            .filter((item) => item.EmployeeID === employeeSelected.EmployeeID)
            .reduce((arr, item) => {
                arr.push(
                    ...data.map(({ EquipmentName, EquipmentID, UnitName, UnitID }) => ({
                        EmployeeID: item.EmployeeID,
                        EquipmentName,
                        UsingPeriodFrom: moment().format("YYYY-MM-DD"),
                        UsingPeriodTo: "",
                        Quantity: 1,
                        EquipmentID,
                        UnitName: UnitName || "",
                        UnitID,
                    })),
                );
                return arr;
            }, []);
        if (!_.isEqual(arrEquipAllocated, gridEquipments)) {
            this.setState(
                {
                    gridEquipments: _.uniqBy([...gridEquipments, ...arrEquipAllocated], (v) => [v.EmployeeID, v.EquipmentID].join()),
                    showW51F1002Popup: false,
                },
                () => {
                    Config.notify.show("success", Config.lang("DHR_CCLD_da_cap_phat"), 2000);
                },
            );
        }
    };

    onAddAllocations = () => {
        this.setState({
            isMode: 1,
            edit: true,
            rowData: null,
            showW51F1002Popup: true,
        });
    };

    openEmployeeSelect = () => {
        this.setState({ showEmployeeSelect: true });
        this.isRenderModalW09F6000 = true;
    };

    closeEmployeeSelect = () => {
        this.setState({ showEmployeeSelect: false });
    };

    onSelectedEmployee = () => {
        this.loadSelectedEmployees();
        this.setState({ showEmployeeSelect: false });
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState((prevState) => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    onSave = async () => {
        const {
            gridEmployees,
            gridEquipments,
            masterData: { warehouseID, reason, examineDate },
        } = this.state;
        const { FormID } = this;
        const { TransGroupID } = this.getInfo();
        if (!this.validationField()) return;
        const arrEmpIDNotAllocated = gridEmployees.map((item) => item.EmployeeID).filter(this.checkEmployeeAllocation);
        const arrEquipNotAllocated = await this.checkEquipmentInventory();
        const arrAttachments = this._getAttachments();
        const paramData = gridEmployees.map((itemEmp) => ({
            EmployeeID: itemEmp.EmployeeID,
            ..._.pickBy(itemEmp, (v, k) => _.startsWith(k, "RefNum")),
            detail: gridEquipments
                .filter((itemEquip) => itemEquip.EmployeeID === itemEmp.EmployeeID)
                .map((itemDetail) => ({
                    SignerID: "",
                    ReasonID: "",
                    ProTransID: "",
                    EquipmentStatusID: "",
                    TranTypeID: "",
                    SizeID: "",
                    UnitPrice: "",
                    Amount: "",
                    ..._.pickBy(itemDetail, (v, k) => _.startsWith(k, "EquipRef")),
                    ..._.pick(itemDetail, ["EmployeeID", "EquipmentID", "Quantity", "NoteU", "UsingPeriod", "UsingPeriodTo", "UnitID"]),
                    UsingPeriodFrom: itemDetail.UsingPeriodFrom,
                })),
        }));
        const params = {
            TransGroupID,
            FormID,
            DivisionID: Config.getDivisionID(),
            WareHouseID: warehouseID,
            Reason: reason,
            ExamineDate: examineDate,
            employee: JSON.stringify(paramData),
            arrAttachment: JSON.stringify(arrAttachments),
        };
        if (!_.isEmpty(arrEquipNotAllocated)) {
            const message = arrEquipNotAllocated.map(
                (item) =>
                    `<br> ${item.name}: ${Config.lang("DHR_Ton")}: (${item.inventory}) - ${Config.lang("DHR_Cap_phat")}: (${
                        item.allocated
                    })`,
            );
            const messageErr = Config.lang("DHR_So_luong_ton_kho_khong_du_cap_phat") + message;
            Config.popup.show("INFO", messageErr);
            return;
        }
        if (!_.isEmpty(arrEmpIDNotAllocated)) {
            this.setState({ employeeIDNotAllocated: arrEmpIDNotAllocated });
            Config.popup.show("YES_NO", Config.lang("DHR_Ton_tai_nhan_vien_chua_duoc_cap_phat_CCLD_._Ban_co_muon_luu_khong?"), () => {
                this.onSaveApi(params);
            });
        } else {
            this.setState({ employeeIDNotAllocated: [] });
            this.onSaveApi(params);
        }
    };

    onSaveApi = async (params) => {
        const { mode } = this.getInfo();
        this.setState({ gridEmpLoading: true, gridEquipLoading: true, attachmentLoading: true, disabled: true, loadingSave: true });
        const res = await Api.put(`/w51f1001/${mode}`, params);
        this.setState({ gridEmpLoading: false, gridEquipLoading: false, attachmentLoading: false, disabled: false, loadingSave: false });
        if (_.get(res, "data.Status", null) === 0) {
            Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
            this._removeCDN();
            this.setState({ disableSave: true });
        } else {
            Config.popup.show("INFO", Config.lang("DHR_Luu_khong_thanh_cong") + " : " + _.get(res, "message", _.get(res, "data.Message")));
        }
        this.setState({ isSaved: true });
        return "";
    };

    onBack = () => {
        const { isSaved } = this.state;
        let alert = !isSaved ? window.confirm(Config.lang("DHR_Cac_thay_doi_da_thuc_hien_se_khong_duoc_luu.")) : false;
        if (alert || isSaved) {
            browserHistory.push(Config.getRootPath() + "W51F1000");
        }
    };

    onSelectionChangedGridEmployee = (e) => {
        const isEditing = _.isEmpty(_.get(e, "currentDeselectedRowKeys", []));
        const selectedRow = e.selectedRowsData[0];
        if (e.component.hasEditData()) e.component.saveEditData();
        if (!isEditing || this.state.delRow) {
            this.setState({ employeeSelected: selectedRow });
        }
    };

    handleUpdateUsingPeriodTo = (e) => {
        if (this.setTimeoutId) clearTimeout(this.setTimeoutId);
        const newData = _.get(e, "newData", {});

        const columnValid = ["UsingPeriod", "UsingPeriodFrom", "UsingPeriodTo"];
        const keyUpdate = Object.keys(newData);

        if (!_.includes(columnValid, ...keyUpdate)) return;

        this.setTimeoutId = setTimeout(() => {
            const newUsingPeriod = _.get(newData, "UsingPeriod", _.get(e, "oldData.UsingPeriod", ""));
            const newUsingPeriodFrom = _.get(newData, "UsingPeriodFrom", _.get(e, "oldData.UsingPeriodFrom", null));
            let usingPeriodTo = moment(newUsingPeriodFrom)
                .add(Number(newUsingPeriod), "month")
                .subtract(Number(newUsingPeriod) === 0 ? 0 : 1, "days")
                .format("YYYY-MM-DD");
            if (usingPeriodTo === "Invalid date") usingPeriodTo = null;
            e.component.cellValue(this.newRowIndex, "UsingPeriodTo", usingPeriodTo);
        }, 350);
    };

    onDeleteRow = (e, isGridEquipment) => {
        const { data } = e;
        const { gridEquipments } = this.state;
        const employeeID = _.get(data, "EmployeeID", "");
        const equipmentID = _.get(data, "EquipmentID", "");
        const typeGrid = !isGridEquipment ? Config.lang("DHR_Nhan_vien") : Config.lang("DHR_CCLD");
        if (!isGridEquipment && !this.checkEmployeeAllocation(employeeID)) {
            Config.popup.show("INFO", Config.lang("DHR_Ton_tai_nhan_vien_duoc_cap_phat_CCLD_,_Khong_duoc_phep_xoa"));
            return;
        }
        Config.popup.show("YES_NO", `${Config.lang("DHR_Ban_co_chac_muon_xoa?")} ${typeGrid}`, () => {
            if (!isGridEquipment) {
                e.component.deleteRow(e.rowIndex);

                this.setState({
                    delRow: true,
                });
            } else {
                this.setState({
                    gridEquipments: _.remove(gridEquipments, (item) =>
                        item.EquipmentID === equipmentID ? item.EmployeeID !== employeeID : true,
                    ),
                });
            }
        });
    };

    onCopyRow = (e) => {
        const { gridEquipments, gridEmployees } = this.state;
        const rowCopied = _.get(e, "row.data", false);
        if (!rowCopied) return;
        Config.popup.show(
            "YES_NO",
            `${Config.lang("DHR_Sao_chep_CCLD")} ${rowCopied.EquipmentName} ${Config.lang("DHR_Cho_tat_ca_nhan_vien")}`,
            () => {
                const cpEquipment = gridEmployees
                    .filter((emp) => emp.EmployeeID !== rowCopied.EmployeeID)
                    .reduce((arr, item) => {
                        arr.push({ ...rowCopied, EmployeeID: item.EmployeeID });
                        return arr;
                    }, []);
                if (_.isEmpty(cpEquipment)) return;
                this.setState(
                    { gridEquipments: _.uniqBy([...gridEquipments, ...cpEquipment], (v) => [v.EmployeeID, v.EquipmentID].join()) },
                    () => {
                        Config.notify.show("success", `${Config.lang("DHR_Sao_chep_thanh_cong")}: ${rowCopied.EquipmentName}`, 3000);
                    },
                );
            },
        );
    };

    onChangeMasterField = (key, data) => {
        const value = _.get(data, "value", _.get(data, "target.value", ""));
        this.setState({ masterData: { ...this.state.masterData, [key]: value } });
    };

    closeEmployeeSelect = () => {
        this.setState({ showEmployeeSelect: false });
    };

    renderCaption = (grid) => {
        const { getCaption } = this.props;
        const captionGrid = _.get(getCaption, grid, false);
        if (!captionGrid) return;
        return captionGrid
            .filter((item) => !item.Disabled)
            .map((item, ind) => {
                let format = {};
                if (item.IsMoney) format = { format: { type: "fixedPoint" } };
                return (
                    <Column
                        width={220}
                        allowEditing={true}
                        key={ind}
                        caption={item.RefCaption}
                        dataField={item.RefID}
                        alignment={"center"}
                        {...format}
                    />
                );
            });
    };

    renderEmpProfile = (e) => {
        const { data } = e.row;
        const { employeeIDNotAllocated } = this.state;
        const { classes } = this.props;
        const employeeId = _.get(data, "EmployeeID", "");
        let renderIconWarning = "";
        if (_.includes(employeeIDNotAllocated, _.get(data, "EmployeeID", ""))) {
            renderIconWarning = (
                <Tooltip title={Config.lang("DHR_Nhan_vien_chua_duoc_cap_phat_CCLD")} arrow={"true"}>
                    <IconButton aria-label="warning" style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}>
                        <WarningIcon fontSize="small" style={{ color: "#FFCC00", padding: 0 }} />
                    </IconButton>
                </Tooltip>
            );
        }
        return (
            <div style={{ display: "flex" }}>
                {renderIconWarning}
                <div className={classes.avatarEmployees}>
                    <UserImage width={28} height={30} data={data} allowHover={false} />
                    {employeeId}
                </div>
            </div>
        );
    };

    renderUsingPeriodFrom = (e) => {
        const { data } = e.row;
        const usingPeriodFrom = _.get(data, "UsingPeriodFrom", "");
        return (
            <DateBoxPicker
                InputLabelProps={{
                    shrink: true,
                }}
                useMaskBehavior={true}
                width={"100%"}
                stylingMode={"underlined"}
                shrink={true}
                value={usingPeriodFrom}
                margin={"normal"}
                required={true}
                showClearButton={true}
            />
        );
    };

    renderButtonAction = (e, isGridEquipment = "") => {
        return (
            <GridActionBar>
                <div className="">
                    {isGridEquipment && (
                        <IconButton
                            disabled={!e.data.Quantity > 0}
                            aria-label="copy"
                            style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}
                            onClick={() => this.onCopyRow(e)}
                        >
                            <FileCopyIcon fontSize="small" />
                        </IconButton>
                    )}

                    <IconButton
                        aria-label="delete"
                        style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
                        onClick={() => this.onDeleteRow(e, isGridEquipment)}
                    >
                        <Icons name={"delete"}/>
                    </IconButton>
                </div>
            </GridActionBar>
        );
    };

    validationField = () => {
        const { masterData } = this.state;
        const validateRules = [
            {
                name: "ExamineDate",
                rules: "isRequired",
                value: masterData.examineDate,
            },
            {
                name: "WareHouseID",
                rules: "isRequired",
                value: masterData.warehouseID,
            },
        ];
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length !== 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            return true;
        }
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value,
            },
        });
        return Object.keys(value).length !== 0;
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { isPermissionForm, mode } = this.getInfo();
        const {
            disabled,
            gridEmpLoading,
            gridEquipLoading,
            showEmployeeSelect,
            gridEmployees,
            gridEquipments,
            employeeSelected,
            masterData,
            dataOldAttachments,
            uploading,
            attachmentLoading,
            showW51F1002Popup,
            error,
            disableSave,
            loadingSave,
        } = this.state;
        const { classes, getCboWarehouse } = this.props;
        const warehouseNameSelected = _.get(
            getCboWarehouse.filter((item) => item.WareHouseID === masterData.warehouseID),
            "[0]WareHouseName",
            "",
        );
        const gridEquipEmployee = employeeSelected ? gridEquipments.filter((item) => item.EmployeeID === employeeSelected.EmployeeID) : [];
        return (
            <>
                <ActionToolbar title={Config.lang("DHR_Cap_phat_trang_thiet_bi_lao_dong")} onBack={this.onBack}>
                    <div className={classes.buttonHeader}>
                        <ButtonCustom
                            name={Config.lang("DHR_Chon_nhan_vien")}
                            color={"primary"}
                            variant={"custom"}
                            icon={<InlineSVG className={"btn-icon-svg"} src={require("../../../assets/images/general/people.svg")} />}
                            style={{ textTransform: "uppercase" }}
                            size={"large"}
                            className={"mgr5"}
                            disabled={disabled}
                            onClick={this.openEmployeeSelect}
                        />
                        <ButtonGeneral
                            name={Config.lang("DHR_Luu")}
                            typeButton={"save"}
                            disabled={!gridEmployees.length > 0 || disabled || isPermissionForm < 2 || disableSave}
                            loading={loadingSave}
                            style={{ textTransform: "uppercase" }}
                            size={"large"}
                            onClick={this.onSave}
                        />
                    </div>
                </ActionToolbar>
                {(showEmployeeSelect || this.isRenderModalW09F6000) && (
                    <W09F6000
                        open={showEmployeeSelect}
                        FormID={"W51F1001"}
                        onChosen={this.onSelectedEmployee}
                        onClose={this.closeEmployeeSelect}
                    />
                )}
                <Modal open={showW51F1002Popup} maxWidth={"lg"} fullWidth={true}>
                    <W51F1002
                        open={showW51F1002Popup}
                        onClose={this.closePopupAllocation}
                        onChosen={this.onChosenAllocation}
                        isMode={this.state.isMode}
                        FormID={"W51F1002"}
                        WareHouseIDDef={masterData.warehouseID}
                    />
                </Modal>
                <FormGroup>
                    <Row>
                        <Col xl={3} lg={3} xs={12}>
                            <Tooltip
                                classes={{
                                    popper: classes.tooltipWarehouse,
                                }}
                                title={warehouseNameSelected}
                                placement={"bottom"}
                            >
                                <div>
                                    <Combo
                                        selectProps={{ readOnly: mode === "edit" }}
                                        dataSource={getCboWarehouse}
                                        label={
                                            <>
                                                <IconButton
                                                    style={{ marginRight: 10 }}
                                                    ria-label="view"
                                                    size="small"
                                                    onClick={this.onViewAllocations}
                                                >
                                                    <HouseIcon />
                                                </IconButton>
                                                {Config.lang("DHR_Kho")}
                                            </>
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { marginTop: -5 },
                                        }}
                                        displayExpr={"WareHouseName"}
                                        keyExpr={"WareHouseID"}
                                        valueExpr={"WareHouseID"}
                                        margin={"normal"}
                                        required
                                        stylingMode={"underlined"}
                                        disabled={disabled}
                                        value={masterData.warehouseID || ""}
                                        shrink={true}
                                        fullWidth
                                        error={error && error["ExamineDate"]}
                                        onValueChanged={(e) => this.onChangeMasterField("warehouseID", e)}
                                    />
                                </div>
                            </Tooltip>
                        </Col>
                        <Col xl={2} lg={2} xs={12}>
                            <DateBoxPicker
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                height={29}
                                useMaskBehavior={true}
                                label={Config.lang("DHR_Ngay_thuc_hien")}
                                width={"100%"}
                                stylingMode={"underlined"}
                                shrink={true}
                                value={masterData.examineDate || ""}
                                margin={"normal"}
                                onValueChanged={(e) => this.onChangeMasterField("examineDate", e)}
                                error={error && error["ExamineDate"]}
                                disabled={disabled}
                                required={true}
                                showClearButton={true}
                            />
                        </Col>
                        <Col xl={5} lg={4} xs={12}>
                            <TextField
                                // error={error && error["AttendanceVoucherName"]}
                                disabled={disabled}
                                label={Config.lang("DHR_Ly_do_cap_phat")}
                                variant={"standard"}
                                margin={"normal"}
                                value={masterData.reason || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => this.onChangeMasterField("reason", e)}
                                fullWidth
                            />
                        </Col>
                        <Col xl={2} lg={3} xs={12}>
                            <div className={classes.buttonCustom}>
                                <ButtonCustom
                                    name={Config.lang("DHR_Sao_chep_cap_phat_truoc")}
                                    color={"primary"}
                                    variant={"custom"}
                                    icon={<FileCopyIcon />}
                                    style={{ textTransform: "uppercase" }}
                                    size={"large"}
                                    className={"mgr5"}
                                    disabled={disabled || !gridEmployees.length > 0}
                                    onClick={() => this.reloadDetail()}
                                />{" "}
                            </div>
                        </Col>
                    </Row>
                    {/* datagrid */}
                </FormGroup>
                <GridContainer
                    reference={(ref) => (this.dataGrid1 = ref)}
                    gridProps={{
                        className: classes.dataGrid,
                    }}
                    editing={{
                        texts: {
                            confirmDeleteMessage: "",
                        },
                        mode: "cell",
                        allowUpdating: true,
                        selectTextOnEditStart: true,
                        startEditAction: Config.isMobile ? "click" : "dblClick",
                    }}
                    selection={{ mode: "single" }}
                    onSelectionChanged={this.onSelectionChangedGridEmployee}
                    // onRowUpdating={(e) => {
                    //     this.setState({ delRow: false });
                    // }}
                    loading={gridEmpLoading}
                    hoverStateEnabled={true}
                    style={{ border: "none" }}
                    dataSource={gridEmployees}
                    showRowLines={false}
                    showBorders={false}
                    showColumnLines={false}
                    noDataText={Config.lang("DHR_No_data")}
                    onContentReady={(e) => {
                        let grid = e.component;
                        let selection = grid.getSelectedRowKeys();
                        if (selection.length === 0) {
                            grid.selectRowsByIndexes([0]);
                        }
                    }}
                >
                    <KeyboardNavigation enterKeyAction={"startEdit"} enterKeyDirection={"column"} />
                    <Column
                        caption={Config.lang("DHR_Hanh_dong")}
                        alignment={"center"}
                        visible={Config.isMobile}
                        allowEditing={false}
                        cellRender={this.renderButtonAction}
                    />
                    <Column
                        width={220}
                        caption={Config.lang("DHR_Ma_nhan_vien")}
                        allowEditing={false}
                        cellRender={this.renderEmpProfile}
                        alignment={"left"}
                    />
                    <Column
                        width={220}
                        allowEditing={false}
                        caption={Config.lang("DHR_Ten_nhan_vien")}
                        dataField={"EmployeeName"}
                        alignment={"left"}
                    />
                    <Column allowEditing={false} caption={Config.lang("DHR_Size_ao")} dataField={"ShirtSize"} alignment={"center"} />
                    <Column allowEditing={false} caption={Config.lang("DHR_Size_quan")} dataField={"TrousersSize"} alignment={"center"} />
                    <Column allowEditing={false} caption={Config.lang("DHR_Size_giay")} dataField={"ShoesSize"} alignment={"center"} />
                    {this.renderCaption("grid1")}
                    <Column
                        alignment={"center"}
                        fixed={true}
                        fixedPosition={"right"}
                        allowEditing={false}
                        visible={!Config.isMobile}
                        cellRender={this.renderButtonAction}
                    />
                </GridContainer>
                <FormGroup>
                    <div className={classes.infoDistribution}>
                        <Typography style={{ marginBottom: 6, fontWeight: 400 }} variant="subtitle1">
                            {Config.lang("DHR_Thong_tin_cap_phat_cho")}
                        </Typography>
                        <div style={{ width: Config.isMobile ? "100%" : "23%", margin: `0px ${Config.isMobile ? 0 : 25}px` }}>
                            <TextField
                                variant={"standard"}
                                value={
                                    !_.isEmpty(employeeSelected)
                                        ? `${employeeSelected.EmployeeID} ${
                                              employeeSelected.EmployeeName ? "- " + employeeSelected.EmployeeName : ""
                                          }`
                                        : ""
                                }
                                InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <div className={classes.avatarEmpSelect}>
                                                <UserImage height={24} width={24} data={employeeSelected || []} />
                                            </div>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </div>
                        <ButtonCustom
                            name={Config.lang("DHR_Chon_CCLD")}
                            color={"primary"}
                            variant={"custom"}
                            icon={<ExitToAppIcon />}
                            style={{ textTransform: "uppercase" }}
                            size={"large"}
                            className={"mgr5"}
                            disabled={disabled || !_.get(employeeSelected, "EmployeeID", false)}
                            onClick={this.onAddAllocations}
                        />
                    </div>
                </FormGroup>
                <FormGroup>
                    <GridContainer
                        gridProps={{
                            className: classes.dataGrid,
                        }}
                        editing={{
                            texts: {
                                confirmDeleteMessage: "",
                            },
                            mode: "cell",
                            allowUpdating: true,
                            selectTextOnEditStart: true,
                            startEditAction: Config.isMobile ? "click" : "dblClick",
                        }}
                        selection={{ mode: "none" }}
                        onRowUpdating={this.handleUpdateUsingPeriodTo}
                        onFocusedCellChanging={(e) => {
                            this.newRowIndex = e.newRowIndex;
                        }}
                        hoverStateEnabled={true}
                        loading={gridEquipLoading}
                        style={{ border: "none" }}
                        dataSource={gridEquipEmployee}
                        showRowLines={false}
                        showBorders={false}
                        typeShort={true}
                        keyExpr={"EquipmentID"}
                        showColumnLines={false}
                        noDataText={Config.lang("DHR_No_data")}
                    >
                        <KeyboardNavigation enterKeyAction={"startEdit"} enterKeyDirection={"column"} />
                        <Column
                            caption={Config.lang("DHR_Hanh_dong")}
                            alignment={"center"}
                            allowEditing={false}
                            visible={Config.isMobile}
                            cellRender={(e) => this.renderButtonAction(e, true)}
                        />
                        <Column
                            allowEditing={false}
                            caption={Config.lang("DHR_STT")}
                            alignment={"center"}
                            cellRender={(e) => e.rowIndex + 1}
                        />
                        <Column
                            allowEditing={false}
                            caption={Config.lang("DHR_Cong_cu_lao_dong")}
                            cellRender={(e) => (
                                <div
                                    style={{ display: "flex", height: 30, alignItems: "center" }}
                                >{`${e.data.EquipmentName} - ${e.data.EquipmentID}`}</div>
                            )}
                            cssClass={"text-left"}
                        />
                        <Column allowEditing={false} caption={Config.lang("DHR_Don_vi_tinh")} alignment={"center"} dataField={"UnitName"} />
                        <Column dataType="number" caption={Config.lang("DHR_So_luong")} alignment={"center"} dataField={"Quantity"}>
                            <RequiredRule />
                            <CustomRule message={Config.lang("DHR_So_luong_phai_lon_hon_0")} validationCallback={(e) => e.value > 0} />
                        </Column>
                        <Column
                            caption={Config.lang("DHR_So_thang_su_dung")}
                            alignment={"center"}
                            dataField={"UsingPeriod"}
                            dataType={"number"}
                        />
                        <Column
                            caption={Config.lang("DHR_Ngay_cap")}
                            alignment={"center"}
                            dataField={"UsingPeriodFrom"}
                            width={230}
                            dataType="date"
                        />
                        <Column
                            allowEditing={false}
                            caption={Config.lang("DHR_Ngay_het_han")}
                            dataField={"UsingPeriodTo"}
                            alignment={"center"}
                            dataType="date"
                        />
                        {this.renderCaption("grid2")}
                        <Column width={420} caption={Config.lang("DHR_Ghi_chu")} dataField={"NoteU"} alignment={"left"} />
                        <Column
                            alignment={"center"}
                            fixed={true}
                            fixedPosition={"right"}
                            allowEditing={false}
                            visible={!Config.isMobile}
                            cellRender={(e) => this.renderButtonAction(e, true)}
                        />
                    </GridContainer>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col md={6}>
                            <Attachments
                                ref={(ref) => (this.attRef = ref)}
                                showButton={true}
                                files={dataOldAttachments}
                                disabled={uploading}
                                maxLength={5}
                                uploading={attachmentLoading}
                                onUploading={this.onUploading}
                                onChanged={this.onChangeAttachments}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <ButtonCustom
                                name={Config.lang("DHR_Dinh_kem")}
                                color={"primary"}
                                variant={"custom"}
                                icon={<AttachFileIcon className={classes.attachmentIcon} />}
                                style={{ textTransform: "uppercase" }}
                                size={"large"}
                                className={"mgr5"}
                                disabled={disabled}
                                onClick={this.onAttachment}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </>
        );
    }

    componentDidMount = async () => {
        if (!this.getInfo()) return;
        const { mode } = this.getInfo();
        this.loadCaptions();
        this.loadCboWarehouse();
        if (mode === "edit") {
            this.loadDetail();
            this.loadMaster();
            this.loadAttachments();
        }
        window.addEventListener("beforeunload", this.beforeUnload);
    };

    beforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = "";
        return;
    };

    componentWillUnmount = () => {
        window.removeEventListener("beforeunload", this.beforeUnload);
    };
}
export default compose(
    connect(
        (state) => ({
            getCaption: state.W51F1001.getCaption,
            getCboWarehouse: state.general.getCboWarehouse,
        }),
        (dispatch) => ({
            w51f1001Actions: bindActionCreators(W51F1001Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        }),
    ),
    withStyles(styles, { withTheme: true }),
)(W51F1001);
